<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Customer</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-expand-transition>
                <v-row>
                  <v-col cols="12">
                    <v-row
                      no-gutters
                      align="center"
                    >
                      <v-col cols="auto">
                        <v-label>
                          Billing Type
                        </v-label>
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip
                          max-width="350"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              color="primary"
                              class="ml-2"
                              v-bind="attrs"
                              style="cursor: pointer;"
                              v-on="on"
                            >
                              {{ icons.mdiInformationOutline }}
                            </v-icon>
                          </template>
                          <span>This indicates the method of billing for the customer. Choose 'Prepaid' if the customer pays before using the service or 'Postpaid' if the customer pays after usage.</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-radio-group
                      v-model="form.billing_type"
                      :disabled="isBillingTypeDisabled"
                      row
                    >
                      <v-radio
                        label="Prepaid"
                        :value="1"
                      ></v-radio>
                      <v-radio
                        label="Postpaid"
                        :value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="form.internet_connection_type"
                      label="Internet Connection Type"
                      :items="internetConnectionTypes"
                      item-text="text"
                      item-value="value"
                      required
                      outlined
                    ></v-select>
                  </v-col>
                  <v-expand-transition>
                    <v-col
                      v-if="form.internet_connection_type === internetConnectionTypeEnum.STATIC"
                      cols="12"
                    >
                      <v-select
                        v-model="form.network_address"
                        label="Network Address"
                        :items="ipNetworks"
                        :item-text="formattedNetworkInfo"
                        item-value="network_address"
                        required
                        outlined
                        :loading="isIpNetworksLoading"
                        :error="form.errors.has('network_address')"
                        :error-messages="form.errors.get('network_address')"
                        hint="This will be used for IP address allocation."
                      ></v-select>
                    </v-col>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-col
                      v-if="form.internet_connection_type === internetConnectionTypeEnum.STATIC || form.internet_connection_type === internetConnectionTypeEnum.DYNAMIC"
                      cols="12"
                      class="pd-0"
                    >
                      <v-text-field
                        v-model="form.mac_address"
                        label="Mac Address"
                        placeholder="e.g 00:00:00:00:00:00"
                        :rules="[
                          v => !!v || 'Mac address is required',
                          v => (v && /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(v)) || 'Invalid MAC address format',
                        ]"
                        required
                        :error="form.errors.has('mac_address')"
                        :error-messages="form.errors.get('mac_address')"
                        autocomplete="new-password"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-expand-transition>
                  <v-col
                    cols="12"
                    class="pd-0"
                  >
                    <v-text-field
                      v-model="form.name"
                      label="Full name"
                      placeholder="e.g John Doe"
                      :rules="[
                        v => !!v || 'Customer name is required',
                      ]"
                      required
                      :error="form.errors.has('name')"
                      :error-messages="form.errors.get('name')"
                      autocomplete="new-password"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    class="pd-0"
                  >
                    <v-text-field
                      v-model="form.username"
                      label="Username"
                      placeholder="e.g 0712345678"
                      :rules="[
                        v => !!v || 'Username is required',
                      ]"
                      required
                      :error="form.errors.has('username')"
                      :error-messages="form.errors.get('username')"
                      hint="This could be a phone number or any unique identifier."
                      outlined
                    >
                      <template v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="generateUsername"
                            >
                              {{ icons.mdiRefresh }}
                            </v-icon>
                          </template>
                          <span>Generate Username</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-expand-transition>
                    <v-col
                      v-if="form.internet_connection_type === internetConnectionTypeEnum.PPPOE || form.internet_connection_type === internetConnectionTypeEnum.HOTSPOT"
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.password"
                        :type="showPassword ? 'text' : 'password'"
                        label="Password"
                        :append-icon="showPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                        :rules="[
                          v => (form.internet_connection_type === internetConnectionTypeEnum.DYNAMIC || !!v) || 'Password is required'
                        ]"
                        :error="form.errors.has('password')"
                        :error-messages="form.errors.get('password')"
                        outlined
                        hint="This password will be used for authentication on the router, not for system login. Remember to keep it confidential."
                        @click:append="showPassword = !showPassword"
                      >
                        <template v-slot:append-outer>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="generatePassword"
                              >
                                {{ icons.mdiRefresh }}
                              </v-icon>
                            </template>
                            <span>Generate Password</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-expand-transition>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.phone_number"
                      label="Phone number"
                      placeholder="e.g 0712345678"
                      :rules="[
                        v => !!v || 'Phone number is required',
                      ]"
                      :error="form.errors.has('phone_number')"
                      :error-messages="form.errors.get('phone_number')"
                      hint="This will be used for communication purposes."
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="form.account_number_same_as_phone_number"
                      label="Use phone number as account number"
                      class="mt-0"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.account_number"
                      label="Account number"
                      placeholder="e.g 0712345678"
                      :rules="[
                        v => !!v || 'Account number is required',
                      ]"
                      :error="form.errors.has('account_number')"
                      :error-messages="form.errors.get('account_number')"
                      hint="This will be used for billing and payment purposes."
                      persistent-hint
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-checkbox
                      v-if="action === 'Save'"
                      v-model="form.should_pay_installation_fee"
                      label="Should Pay Installation Fee"
                      class="mt-0"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-expand-transition>
                    <v-col
                      v-if="form.should_pay_installation_fee || action === 'Update'"
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.installation_fee"
                        label="Installation Fee Amount"
                        :rules="[v => !!v || 'Installation fee is required', v => /^[0-9]+$/.test(v) || 'Must be numeric']"
                        :error="form.errors.has('installation_fee')"
                        :error-messages="form.errors.get('installation_fee')"
                        hint="This is the amount to be paid for installation fee. Input numbers only, no commas"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-expand-transition>
                  <v-col
                    v-if="action === 'Save'"
                    cols="12"
                  >
                    <v-text-field
                      v-model="form.debt"
                      label="Debt"
                      :rules="[v => v !== null && v !== '' && /^[0-9]+$/.test(v) || 'Debt amount must be numeric']"
                      :error="form.errors.has('debt')"
                      :error-messages="form.errors.get('debt')"
                      hint="Enter any existing debt amount for the new customer. This should reflect any outstanding balance they may previously had. Input numbers only, no commas."
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="action === 'Save'"
                    cols="12"
                  >
                    <v-text-field
                      v-model="form.credits"
                      label="Credits"
                      :rules="[v => v !== null && v !== '' && /^[0-9]+$/.test(v) || 'Credits amount must be numeric']"
                      :error="form.errors.has('credits')"
                      :error-messages="form.errors.get('credits')"
                      hint="Enter any credits the new customer may previously had. This should reflect any positive balance or credit they have. Input numbers only, no commas."
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.id_number"
                      label="ID Number(Optional)"
                      placeholder="e.g 123456789"
                      :error="form.errors.has('id_number')"
                      :error-messages="form.errors.get('id_number')"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.email"
                      label="Email address (optional)"
                      placeholder="e.g example@gmail.com"
                      :rules="[
                        v => !v || /.+@.+/.test(v) || 'Email address must be valid'
                      ]"
                      :error="form.errors.has('email')"
                      :error-messages="form.errors.get('email')"
                      hint="This is optional but recommended for communication purposes."
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="form.address"
                      clearable
                      label="Address (optional)"
                      outlined
                      rows="2"
                      auto-grow
                      :error="form.errors.has('address')"
                      :error-messages="form.errors.get('address')"
                      hint="The customer's physical address."
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <customer-tag-search-autocomplete
                      v-model="form.tags"
                      class="mb-6"
                      :form-errors="form.errors"
                      @change="onTagChange"
                    />
                  </v-col>
                  <v-col
                    v-if="selectedStation === 'all'"
                    cols="12"
                  >
                    <v-select
                      v-model="form.station_id"
                      label="Station"
                      :items="stations"
                      item-text="name"
                      item-value="id"
                      required
                      outlined
                      :loading="isStationsLoading"
                      :error="form.errors.has('station_id')"
                      :error-messages="form.errors.get('station_id')"
                    ></v-select>
                  </v-col>

                  <v-expand-transition>
                    <v-col
                      v-show="!(selectedStation === 'all' && form.station_id === '')"
                      cols="12"
                    >
                      <v-select
                        v-model="form.internet_plan_id"
                        :items="internetPlans"
                        item-text="name"
                        item-value="id"
                        label="Internet Plan"
                        :rules="[v => !!v || 'Internet Plan is required']"
                        :error="form.errors.has('internet_plan_id')"
                        :error-messages="form.errors.get('internet_plan_id')"
                        :loading="isInternetPlansLoading"
                        hint="This determines the user's internet speed and usage limits."
                        outlined
                        required
                      >
                        <template v-slot:selection="data">
                          {{ data.item.name }} ({{ formatValidity(data.item.validity_unit, data.item.validity) }})
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title> {{ data.item.name }} ({{ formatValidity(data.item.validity_unit, data.item.validity) }})</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-select>
                      <span
                        v-if="!isInternetPlansLoading && internetPlans.length === 0"
                      >
                        There are no any Internet Plans available. Please go to <router-link :to="{ name: 'internet-plans', params: { internet_connection_type: form.internet_connection_type } }">Internet Plans</router-link>
                        page and create one.
                      </span>
                    </v-col>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-col
                      v-show="form.internet_plan_id"
                      cols="12"
                    >
                      <v-row
                        no-gutters
                        align="center"
                      >
                        <!-- Checkbox -->
                        <v-col cols="auto">
                          <v-checkbox
                            v-model="form.use_custom_price"
                            label="Use Custom Price for Internet Plan"
                            class="mt-0"
                            color="primary"
                            hide-details
                            @change="toggleCustomPrice"
                          ></v-checkbox>
                        </v-col>

                        <!-- Tooltip -->
                        <v-col cols="auto">
                          <v-tooltip
                            max-width="300"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                color="primary"
                                class="ml-2"
                                v-bind="attrs"
                                style="cursor: pointer;"
                                v-on="on"
                              >
                                {{ icons.mdiInformationOutline }}
                              </v-icon>
                            </template>
                            <span>By checking this box, you can set a custom price for the internet plan specifically for this customer.</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-col
                      v-if="form.use_custom_price"
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.custom_price"
                        label="Custom Price"
                        :rules="[v => !!v || 'Custom Price is required', v => /^[0-9]+$/.test(v) || 'Must be numeric']"
                        :error="form.errors.has('custom_price')"
                        :error-messages="form.errors.get('custom_price')"
                        hint="Set a custom price for the customer's internet plan. Input numbers only, no commas"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-expand-transition>

                  <v-expand-transition>
                    <v-col
                      v-show="form.internet_plan_id"
                      cols="12"
                    >
                      <v-row
                        no-gutters
                        align="center"
                      >
                        <!-- Checkbox -->
                        <v-col cols="auto">
                          <v-checkbox
                            v-model="form.has_discount"
                            label="Apply Discount to internet plan"
                            class="mt-0"
                            color="primary"
                            hide-details
                          ></v-checkbox>
                        </v-col>

                        <!-- Tooltip -->
                        <v-col cols="auto">
                          <v-tooltip
                            max-width="300"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                color="primary"
                                class="ml-2"
                                v-bind="attrs"
                                style="cursor: pointer;"
                                v-on="on"
                              >
                                {{ icons.mdiInformationOutline }}
                              </v-icon>
                            </template>
                            <span>By checking this box, you can give discount to this customer</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-col
                      v-if="form.has_discount"
                      class="ma-0 pa-0"
                      cols="12"
                    >
                      <v-col class="d-md-flex">
                        <v-text-field
                          v-model="form.discount_value"
                          label="Discount Value"
                          class="rounded-r-md-0 discount-value-text-field-width"
                          :rules="[v => !!v || 'Custom Price is required', v => /^[0-9]+$/.test(v) || 'Must be numeric']"
                          :error="form.errors.has('discount_value')"
                          :error-messages="form.errors.get('custom_price')"
                          hint="Input discount value. Input numbers only, no commas"
                          outlined
                          required
                        ></v-text-field>
                        <v-select
                          v-model="form.discount_type"
                          class="rounded-l-md-0"
                          :items="[{
                            text: 'Percent',
                            value: 1,
                          }, {
                            text: 'Fixed Amount',
                            value: 2,
                          }]"
                          item-text="text"
                          item-value="value"
                          outlined
                          label="Value type"
                        ></v-select>
                      </v-col>
                    </v-col>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-col
                      v-if="form.has_discount"
                      cols="12"
                    >
                      <date-time-picker
                        :label="'Discount start date'"
                        :default-date-time="form.discount_start_date"
                        @change="updateDiscountStartDate"
                      />
                      <small
                        v-show="form.errors.has('discount_start_date')"
                        class="validation-error"
                      >
                        {{ form.errors.get('discount_start_date') }}
                      </small>
                    </v-col>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-col
                      v-if="form.has_discount"
                      cols="12"
                    >
                      <date-time-picker
                        :label="'Discount end date'"
                        :default-date-time="form.discount_end_date"
                        @change="updateDiscountEndDate"
                      />
                      <small
                        v-show="form.errors.has('discount_end_date')"
                        class="validation-error"
                      >
                        {{ form.errors.get('discount_end_date') }}
                      </small>
                    </v-col>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-col
                      v-if="form.has_discount"
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.discount_message"
                        label="Discount Message"
                        :error="form.errors.has('discount_message')"
                        :error-messages="form.errors.get('discount_message')"
                        hint="Enter a brief message describing the discount details. e.g 'Free 1 month subscription'"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-expand-transition>
                </v-row>
              </v-expand-transition>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <add-internet-plan-price-dialog
      :show-dialog="showAddInternetPlanPriceDialog"
      :internet-plan="internetPlan"
      @close="
        showAddInternetPlanPriceDialog = false
        internetPlan = {}
      "
      @internet-plan-price-added="clearInternetPlanIdErrors"
    />
  </v-row>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import {
  mdiEyeOutline, mdiEyeOffOutline, mdiRefresh, mdiInformationOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
import billingIntervalName from '@/mixins/billingIntervalName'
import internetConnectionTypes from '@/mixins/internetConnectionTypes'
import internetConnectionTypeEnum from '@/enums/internetConnectionTypeEnum'
import AddInternetPlanPriceDialog from '@/components/dialogs/AddInternetPlanPriceDialog.vue'
import DateTimePicker from '../partials/DateTimePicker.vue'
import CustomerTagSearchAutocomplete from '../partials/CustomerTagSearchAutocomplete.vue'

export default {
  components: {
    AddInternetPlanPriceDialog,
    CustomerTagSearchAutocomplete,
    DateTimePicker,
  },
  mixins: [billingIntervalName, internetConnectionTypes],
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      username: '',
      name: '',
      password: '',
      email: '',
      phone_number: '',
      id_number: '',
      account_number: '',
      account_number_same_as_phone_number: true,
      address: '',
      station_id: '',
      internet_plan_id: '',
      internet_connection_type: '',
      network_address: '',
      mac_address: '',
      installation_fee: '',
      should_pay_installation_fee: false,
      update_installation_fee: false,
      use_custom_price: false,
      custom_price: '',
      billing_type: 1,
      has_discount: false,
      discount_value: 100,
      discount_type: 1,
      discount_message: 'Free 1 month subscription',
      discount_start_date: new Date().toISOString().substr(0, 10),
      discount_end_date: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().substr(0, 10),
      debt: 0,
      credits: 0,
      id: '',
      tags: [],
    }),
    stations: [],
    isStationsLoading: false,
    action: 'Save',
    isInternetPlansLoading: false,
    internetPlans: [],
    isIpNetworksLoading: false,
    ipNetworks: [],
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiRefresh,
      mdiInformationOutline,
    },
    showPassword: false,
    showAddInternetPlanPriceDialog: false,
    internetPlan: {},
    defaultInstallationFee: 0,
    internetConnectionTypeEnum,
  }),
  computed: {
    ...mapGetters(['selectedStation']),
    isBillingTypeDisabled() {
      return this.form.internet_connection_type === this.internetConnectionTypeEnum.HOTSPOT
    },
  },
  watch: {
    user() {
      if (Object.keys(this.user).length > 0 && this.showDialog) {
        this.initializeUpdateCustomer()
      } else {
        this.action = 'Save'
      }
    },
    showDialog(newShowDialog) {
      if (newShowDialog) {
        this.initializeStation()
      }
      if (Object.keys(this.user).length > 0 && this.showDialog) {
        this.initializeUpdateCustomer()
      }
    },
    defaultInstallationFee(newInstallationFee) {
      if (this.action === 'Save') {
        console.log('setting installation fee because default installation fee changed')
        this.form.installation_fee = newInstallationFee
      }
    },
    'form.username': function (newUsername) {
      const phoneNumberPattern = /^(07|011)\d{8}$/

      if (phoneNumberPattern.test(newUsername) && this.action === 'Save') {
        this.form.phone_number = newUsername
      }
    },
    'form.phone_number': function (newContactNumber) {
      if (newContactNumber && newContactNumber.trim() !== '') {
        if (this.form.account_number_same_as_phone_number) {
          this.form.account_number = newContactNumber
        }
      }
    },
    'form.account_number_same_as_phone_number': function (newSameAsAccountNumber) {
      if (newSameAsAccountNumber) {
        this.form.account_number = this.form.phone_number
      }
    },
    'form.internet_connection_type': function (newInternetConnectionType) {
      if (newInternetConnectionType === this.internetConnectionTypeEnum.HOTSPOT) {
        this.form.billing_type = 1
      }
      if (newInternetConnectionType && newInternetConnectionType !== '') {
        this.fetchInternetPlans()
      }
    },
    selectedStation(newStation) {
      if (newStation === 'all' && this.stations.length === 0) {
        this.fetchStations()
      } else {
        this.form.station_id = this.selectedStation
        this.fetchInternetPlans()
      }
    },
    'form.station_id': function (newStationId) {
      if (newStationId && newStationId.trim() !== '') {
        this.fetchInternetPlans()
      }
    },
  },
  mounted() {
    this.initializeStation()
    if (this.action === 'Save') {
      this.fetchInstallationFee()
    }
    if (Object.keys(this.user).length > 0 && this.showDialog) {
      this.initializeUpdateCustomer()
    }
  },
  methods: {
    formattedNetworkInfo(item) {
      return `${item.network_address}/${item.prefix_length} - ${item.interface}`
    },
    initializeUpdateCustomer() {
      this.form.update(this.user)
      this.form.phone_number = this.user.phone_number
      this.form.id_number = this.user.id_number
      this.form.account_number = this.user.customer.account_number
      this.form.internet_plan_id = this.user.customer.customer_plan ? this.user.customer.customer_plan.internet_plan_id : ''
      this.form.internet_connection_type = this.user.customer.internet_connection_type
      this.form.password = this.user.customer.router_auth_password
      this.form.station_id = this.user.customer.station_id
      this.form.address = this.user.customer.address
      this.form.billing_type = this.user.customer.billing_type
      this.form.has_discount = this.user.customer.has_discount
      this.form.account_number_same_as_phone_number = this.user.phone_number === this.user.customer.account_number
      if (this.user.customer.has_discount) {
        this.form.discount_value = parseInt(this.user.customer.discount.value, 10)
        this.form.discount_type = this.user.customer.discount.type
        this.form.discount_message = this.user.customer.discount.message
        this.form.discount_start_date = moment(String(this.user.customer.discount.start_date)).format('YYYY-MM-DDTHH:mm:ss')
        this.form.discount_end_date = moment(String(this.user.customer.discount.end_date)).format('YYYY-MM-DDTHH:mm:ss')
      }
      if (this.user.customer.ip_assignment) {
        this.form.mac_address = this.user.customer.mac_address
        this.form.network_address = this.user.customer.ip_assignment.ip_network.network_address
      }
      if (this.user.customer.internet_connection_type === this.internetConnectionTypeEnum.STATIC || this.user.customer.internet_connection_type === this.internetConnectionTypeEnum.DYNAMIC) {
        this.form.mac_address = this.user.customer.mac_address
      }
      if (this.user.customer.customer_plan && this.user.customer.customer_plan.has_custom_price) {
        this.form.use_custom_price = true
        this.form.custom_price = this.user.customer.customer_plan.custom_price
      }
      if (this.user.customer.tags) {
        this.form.tags = this.user.customer.tags.map(tag => tag.name.en)
      }
      let installationFee = 0
      if (this.user.customer.should_pay_installation_fee) {
        this.form.should_pay_installation_fee = this.user.customer.should_pay_installation_fee
        installationFee = this.user.customer.installation_fee
        console.log('setting installation fee because setting user')
      }
      this.form.installation_fee = installationFee
      this.action = 'Update'
    },
    initializeStation() {
      if (this.selectedStation === 'all') {
        this.fetchStations()
      } else {
        if (this.action === 'Save') {
          this.form.station_id = this.selectedStation
        }
        this.fetchInternetPlans()
      }
      this.fetchIpNetworks()
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form = new Form({
        username: '',
        name: '',
        password: '',
        email: '',
        phone_number: '',
        account_number: '',
        account_number_same_as_phone_number: true,
        station_id: '',
        internet_plan_id: '',
        internet_connection_type: '',
        network_address: '',
        mac_address: '',
        should_pay_installation_fee: false,
        installation_fee: '',
        billing_type: 1,
        has_discount: false,
        discount_start_date: new Date().toISOString().substr(0, 10),
        discount_end_date: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().substr(0, 10),
        debt: 0,
        credits: 0,
        tags: [],
        id: '',
      })
    },
    generateUsername() {
      const adjectives = ['Quick', 'Brave', 'Clever', 'Bright', 'Mighty', 'Silent', 'Wise', 'Fierce', 'Bold', 'Swift', 'Cunning', 'Noble', 'Fearless', 'Gentle', 'Loyal', 'Savage', 'Witty', 'Vigilant', 'Daring', 'Steadfast', 'Wandering', 'Radiant', 'Enigmatic', 'Valiant', 'Resolute', 'Intrepid', 'Tenacious', 'Audacious', 'Courageous', 'Adventurous']
      const nouns = ['Lion', 'Eagle', 'Shark', 'Panther', 'Wolf', 'Tiger', 'Hawk', 'Bear', 'Fox', 'Dolphin', 'Elephant', 'Leopard', 'Owl', 'Horse', 'Dragon', 'Serpent', 'Jaguar', 'Falcon', 'Cheetah', 'Raven', 'Phoenix', 'Griffin', 'Titan', 'Centaur', 'Valkyrie', 'Samurai', 'Gladiator', 'Corsair', 'Ninja', 'Sorcerer']

      const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)]
      const randomNoun = nouns[Math.floor(Math.random() * nouns.length)]
      const randomNumber = Math.floor(Math.random() * 100)

      this.form.username = `${randomAdjective}${randomNoun}${randomNumber}`
    },
    generatePassword() {
      let length
      let charset

      if (this.form.internet_connection_type === this.internetConnectionTypeEnum.HOTSPOT) {
        // If internet_connection_type is Hotspot, set length to 4 and charset to numbers only
        length = 4
        charset = '0123456789'
      } else {
        // Standard password generation logic
        length = 12
        charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-='
      }

      let retVal = ''
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
      }

      this.form.password = retVal
    },

    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveUser()
        } else {
          this.updateUser()
        }
      }
    },
    saveUser() {
      this.form
        .post('customers')
        .then(response => {
          this.$toast.success('Customer added successfully')
          this.$emit('close')
          this.$emit('user-added', response.data)
          this.closeDialog()
        })
        .catch(error => {
          this.handleInternetPlanError(error)
          this.$toast.error(error.response.data.message)
        })
    },
    updateUser() {
      this.form
        .put(`customers/${this.user.customer.id}`)
        .then(response => {
          this.$toast.success('Customer updated successfully')
          setTimeout(() => {
            if (response.data.has_pending_plan_change && response.data.pending_plan_change.amount_to_top_up > 0) {
              this.$toast.info(`Customer internet plan will be updated to ${response.data.pending_plan_change.new_plan.name} after payment of Ksh ${response.data.pending_plan_change.amount_to_top_up} is made.`)
            }
          }, 1000)
          this.$emit('close')
          this.$emit('user-updated', response.data)
          this.closeDialog()
        })
        .catch(error => {
          this.handleInternetPlanError(error)
          this.$toast.error(error.response.data.message)
        })
    },
    fetchStations() {
      axios.get('stations', {
        params: {
          perPage: 'all',
        },
      })
        .then(response => {
          this.stations = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    fetchInternetPlans() {
      this.isInternetPlansLoading = true
      axios.get('internet-plans', {
        params: {
          perPage: 'all',
          internet_connection_type: this.form.internet_connection_type,
          stationId: this.form.station_id,
        },
      })
        .then(response => {
          this.internetPlans = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isInternetPlansLoading = false
        })
    },
    fetchIpNetworks() {
      this.isIpNetworksLoading = true
      axios.get('ip-networks', {
        params: {
          perPage: 'all',
          stationId: this.form.station_id,
        },
      })
        .then(response => {
          this.ipNetworks = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isIpNetworksLoading = false
        })
    },
    fetchInstallationFee() {
      axios.get('settings/installation-fee')
        .then(response => {
          this.defaultInstallationFee = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    handleInternetPlanError(error) {
      if (error.response.data.message === 'The selected internet plan does not have a price set. Please set a price for the internet plan before proceeding.') {
        this.internetPlan = this.getSelectedInternetPlan()
        this.showAddInternetPlanPriceDialog = true
      }
    },
    getSelectedInternetPlan() {
      const selectedInternetPlan = this.internetPlans.find(
        plan => plan.id === this.form.internet_plan_id,
      )

      return selectedInternetPlan || null
    },
    clearInternetPlanIdErrors() {
      if (this.form.errors && this.form.errors.internet_plan_id) {
        this.form.errors.internet_plan_id = []
      }
    },
    toggleCustomPrice() {
      if (!this.useCustomPrice) {
        this.custom_price = null
      }
    },
    updateDiscountStartDate(newStartDate) {
      this.form.discount_start_date = moment(newStartDate, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss')
    },
    updateDiscountEndDate(newEndDate) {
      this.form.discount_end_date = moment(newEndDate, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss')
    },
    onTagChange(tags) {
      this.form.tags = tags
    },
  },
}
</script>

<style scoped>
.rounded-r-md-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-l-md-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.discount-value-text-field-width {
  flex: 0 0 70%; /* 70% width */
}
</style>
